import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import * as CryptoJS from 'crypto-js';
import { OtpService } from 'src/app/proxy/services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { USER_SECRATE } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { AppAuthService } from '../../../auth-services/app-auth.service';
import {
  DoctorProfileDto,
  LoginDto,
  LoginResponseDto,
  PatientProfileDto,
} from '../../../proxy/dto-models';
import {
  DoctorProfileService,
  PatientProfileService,
  UserAccountsService,
} from '../../../proxy/services';
import { TosterService } from '../../services/toster.service';
export interface ExtendedLoginDto {
  userName: string;
  password: string;
  otp: string;
}

const loginDto: ExtendedLoginDto = {
  userName: '',
  password: '',
  otp: '',
};

@Component({
  selector: 'app-login-input',

  templateUrl: './login-input.component.html',
  styleUrl: './login-input.component.scss',
})
export class LoginInputComponent implements OnInit, OnDestroy {
  selectedUserType = '';
  defaultAuth: any = {
    mobileNo: '',
    password: '',
  };
  formSubmitted: boolean = false;
  errorMessage: string = '';
  loginForm!: FormGroup;
  resetPasswordForm!: FormGroup;
  loginDto: ExtendedLoginDto = loginDto as ExtendedLoginDto;
  hasError: boolean = false;
  returnUrl!: string;
  subs = new SubSink();
  isLoading: any = false;
  passwordFieldType: string = 'password';
  confirmPasswordFieldType: string = 'password';
  changePasswordShow: boolean = false;
  resetModalShow: boolean = false;
  resetLoading: boolean = false;
  loginResponse: any;
  resetFormSubmitted: boolean = false;
  resetPasswordFieldType = 'password';
  resetConfPasswordFieldType = 'password';
  showOtp = false;
  key: string = USER_SECRATE;
  otp: any;
  otpLoader!: boolean;
  constructor(
    private authService: UserAccountsService,
    private appAuthService: AppAuthService,
    private oAuthService: OAuthService,
    private doctorProfileService: DoctorProfileService,
    private PatientProfileService: PatientProfileService,
    private fb: FormBuilder,
    private _router: Router,
    private ActivatedRoute: ActivatedRoute,
    private ToasterService: TosterService,
    private NormalAuth: AuthService,
    private OtpService: OtpService,
    private TosterService: TosterService
  ) {}

  ngOnInit(): void {
    this.initForm();
    const returnUrl =
      this.ActivatedRoute.snapshot.queryParamMap.get('returnUrl');
    this.returnUrl = decodeURIComponent(returnUrl || '');

    this.ActivatedRoute.queryParams.subscribe((params) => {
      this.selectedUserType = params['userType'];
    });
  }

  get formControl() {
    return this.loginForm.controls;
  }

  initForm() {
    console.log(this.selectedUserType);

    this.loginForm = this.fb.group({
      mobileNo: [
        this.defaultAuth.mobileNo,
        [
          Validators.required,
          Validators.pattern(/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/),
        ],
      ],

      password: [''],
    });
  }

  passwordVisibility(field: string) {
    if (field === 'password') {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    } else if (field === 'confirmPassword') {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    }
  }
  sendOtp() {
    this.isLoading = true;
    let otpData = {
      userName: this.loginForm.get('mobileNo')?.value,
      type: 'login',
    };
    if (this.selectedUserType === 'doctor') {
      return this.onSubmit();
    } else {
      this.NormalAuth.sendOtp(otpData).subscribe({
        next: (res) => {
          if (res.message) {
            this.isLoading = false;
            this.showOtp = true;
            console.log('successfully send otp');
          }
        },
        error: (err) => {
          console.log(err);
          this.errorMessage = 'User not exist! ';
          this.isLoading = false;
          this.showOtp = false;
          console.error('failed to send otp');
        },
      });
    }
  }
  // verify() {
  //   this.errorMessage = '';
  //   this.otpLoader = true;
  //   let otp = this.otp;
  //   if (otp) {
  //     this.NormalAuth.setOtpLoader(true);
  //     this.OtpService.varifyOtp(Number(otp)).subscribe((res: boolean) => {
  //       if (res) {
  //         this.onSubmit();
  //       } else {
  //         this.isLoading = false;
  //         this.errorMessage = 'Invalid Otp!';
  //         this.NormalAuth.setOtpLoader(false);
  //       }
  //     });
  //   }
  // }

  onOtpChange(pin: string) {
    if (pin.length == 4) {
      this.otp = Number(pin);
      this.otpLoader = true;
      this.onSubmit();
    } else {
      console.log('Pin should be 4 character');
    }
  }
  goToForgotPassword() {
    this._router.navigate(['/forgot-password'], {
      queryParams: { redirect: 'login' },
    });
  }

  onSubmit(): void {
    const redirect = this.ActivatedRoute.snapshot.queryParams['redirect'];

    this.formSubmitted = true;

    if (!this.loginForm.valid && !this.loginForm.touched) {
      this.ToasterService.customToast(
        'Please fill in all required fields',
        'warning'
      );
      this.isLoading = false;
      return;
    } else {
      if (this.loginForm.invalid) {
        this.isLoading = false;
        return;
      }

      this.formSubmitted = false;
      this.isLoading = true;
      let userType = '';
      this.errorMessage = '';
      this.hasError = false;
      const username = this.formControl['mobileNo'].value;
      const password =
        this.selectedUserType == 'patient'
          ? 'Coppa@123'
          : this.formControl['password'].value;

      this.oAuthService.oidc = false;
      this.loginDto.userName = username;
      this.loginDto.password = password;
      this.loginDto.otp = this.selectedUserType == 'patient' ? this.otp : null;

      try {
        this.appAuthService.isLoadingSubject.next(true);
        this.NormalAuth.signin(this.loginDto).subscribe({
          next: (userInfo) => {
            if (userInfo.loginData.roleName === 'Patient') {
              this.isLoading = false;
              this.otpLoader = false;
              this.subs.sink = this.PatientProfileService.getByUserName(
                userInfo.loginData.userName ? userInfo.loginData.userName : ''
              ).subscribe(
                (patientDto: PatientProfileDto) => {
                  let saveLocalStorage = {
                    fullName: patientDto.fullName,
                    userId: patientDto.userId,
                    id: patientDto.id,
                    userpc: this.encrypt(String(patientDto.mobileNo)),
                    userType: userInfo.loginData.roleName
                      .toString()
                      .toLowerCase(),
                  };
                  this.NormalAuth.setAuthInfoInLocalStorage(saveLocalStorage);
                  let userType =
                    userInfo.loginData.roleName.toString() + '/dashboard';

                  if (redirect) {
                    this.navigateToNextJS(redirect);
                  } else if (this.returnUrl) {
                    this._router.navigate([this.returnUrl]);
                    return;
                  } else {
                    this._router.navigate([userType.toLowerCase()], {
                      state: { data: patientDto },
                    });
                  }
                  this.NormalAuth.setOtpLoader(false);
                },
                (patientError: any) => {
                  // Handle PatientProfile service error
                  this.handleProfileError(patientError);
                }
              );
            }

            if (userInfo.loginData.roleName == 'Doctor') {
              this.isLoading = false;
              this.NormalAuth.setOtpLoader(false);
              this.subs.sink = this.doctorProfileService
                .getByUserName(
                  userInfo.loginData.userName ? userInfo.loginData.userName : ''
                )
                .subscribe(
                  (doctorDto: DoctorProfileDto) => {
                    this.otpLoader = false;
                    let saveLocalStorage = {
                      identityNumber: doctorDto.identityNumber,
                      fullName: doctorDto.fullName,
                      bmdcRegNo: doctorDto.bmdcRegNo,
                      isActive: doctorDto.isActive,
                      userId: doctorDto.userId,
                      id: doctorDto.id,
                      specialityId: doctorDto.specialityId,
                      profileStep: doctorDto.profileStep,
                      createFrom: doctorDto.createFrom,
                      userType: userInfo.loginData.roleName
                        .toString()
                        .toLowerCase(),
                    };
                    this.NormalAuth.setAuthInfoInLocalStorage(saveLocalStorage);
                    if (
                      doctorDto.profileStep == 1 ||
                      doctorDto.profileStep == 2
                    ) {
                      userType = '/signup';
                      //this._router.navigate(['/signup']);
                    } else {
                      userType = doctorDto.isActive
                        ? userInfo.loginData.roleName.toString() + '/dashboard'
                        : userInfo.loginData.roleName.toString() +
                          '/profile-settings/basic-info';
                    }

                    if (redirect) {
                      this.navigateToNextJS(redirect);
                    } else {
                      this._router.navigate([userType.toLowerCase()], {
                        state: { data: doctorDto },
                      });
                    }
                    this.NormalAuth.setOtpLoader(false);
                  },
                  (doctorError: any) => {
                    // Handle DoctorProfile service error

                    this.handleProfileError(doctorError);
                  }
                );
            }

            if (userInfo.loginData?.roleName === 'Agent') {
              this.hasError = true;
              this.isLoading = false;
              this.otpLoader = false;
              this.NormalAuth.setOtpLoader(false);
              this.errorMessage =
                'You are a Agent!. Please login from Agent portal.';
            }
          },
          error: (errorResponse) => {
            this.hasError = true;
            this.appAuthService.isLoadingSubject.next(false);
            this.otpLoader = false;
            this.errorMessage =
              errorResponse.error.error_description ||
              errorResponse.error.message;
            this.isLoading = false;
            this.NormalAuth.setOtpLoader(false);
          },
        });
      } catch (error: any) {
        this.otpLoader = false;
        this.hasError = true;
        if (error.message === "'tokenEndpoint' should not be null") {
          this.errorMessage = 'Identity server is not running';
        }
      }
    }
  }
  private encrypt(user: string): string {
    return CryptoJS.AES.encrypt(user, this.key).toString();
  }
  navigateToNextJS(redirect: string) {
    const jwtToken = localStorage.getItem('access_token'); // Get your JWT token from somewhere

    window.open(
      `http://localhost:3000/${redirect}?token=${jwtToken}`,
      '_blank'
    );
  }
  // Additional method to handle profile service errors
  private handleProfileError(error: any): void {
    this.errorMessage = '';
    if (
      error.error.error.message ===
      'There is no entity DoctorProfile with id = !'
    ) {
      this.errorMessage = 'User not found';
    } else {
      this.ToasterService.customToast(
        String(error.error.error.message),
        'error'
      );
    }
  }

  updateQueryParam(userType: string) {
    const queryParams =
      userType !== '' ? { userType: userType } : { userType: null };
    this._router.navigate([], {
      relativeTo: this.ActivatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  backToPrevious() {
    this.updateQueryParam('');
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
